/**
 * This event causes the installment payment modal window to show up
 */
type InstallmentPaymentModalRequestedEvent = Event;

declare global {
  interface WindowEventMap {
    'installment-payment-modal-requested': InstallmentPaymentModalRequestedEvent;
  }
}

function showModal() {
  const modal = document.getElementById('installment-payment-modal');
  modal.removeAttribute('inert');
  modal.classList.remove('hidden');

  type Detail = WindowEventMap['modal-show-requested']['detail'];
  const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
    { detail: { element: modal } }
  );
  dispatchEvent(modalShowEvent);

  addEventListener('shroud-clicked', closeModal);
}

function closeModal() {
  removeEventListener('shroud-clicked', closeModal);

  const modal = document.getElementById('installment-payment-modal');
  modal.setAttribute('inert', '');
  modal.classList.add('hidden');

  type ModalDetail = WindowEventMap['modal-close-requested']['detail'];
  const modalCloseEvent = new CustomEvent<ModalDetail>('modal-close-requested',
    { detail: { element: modal } }
  );
  dispatchEvent(modalCloseEvent);
}

function onDOMContentLoaded(_event: Event) {
  addEventListener('installment-payment-modal-requested', showModal);

  const modal = document.getElementById('installment-payment-modal');

  // TODO: this was previously an error, now it is just a log message. investigate. is this element
  // not always present? if not expected to always be present, this should be documented.

  if (!modal) {
    console.debug('installment payment modal element not found');
    return;
  }

  const closeButtons = modal.querySelectorAll<HTMLButtonElement>('button');
  for (const button of closeButtons) {
    button.addEventListener('click', closeModal);
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
